<template>
  <section class="invoice-add-wrapper">
    <b-alert variant="danger" :show="ordernumber === undefined">
      <h4 class="alert-heading">
        {{ $t("Error fetching order") }}
      </h4>
      <div class="alert-body">
        {{ $t("Please select an order to print") }}
        <b-link class="alert-link" :to="{ name: 'shipments' }">
          {{ $t("Shipment List") }}.
        </b-link>
      </div>
    </b-alert>
    <b-row class="invoice-add" v-if="ordernumber">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <validation-observer ref="transportValidation">
          <b-form @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <!-- Header -->
              <b-card-body class="pb-0">
                <div class="flex-column invoice-spacing mt-0">
                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      md="12"
                      class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
                    >
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalPrintRecords"
                        per-page="1"
                        first-number
                        last-number
                        class="mb-0 mt-1 float-right mr-1"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Header: Left Content -->
                    <b-col cols="6">
                      <div class="d-flex">
                        <b-row class="flex-grow-1">
                          <b-col cols="10">
                            <div class="">
                              <b-card-text class="mb-25">
                                <b-form-input
                                  v-model="orderdata.deliverName"
                                  id="invoice-data-id"
                                  :placeholder="$t('Name')"
                                  disabled
                                />
                              </b-card-text>
                              <b-card-text class="mb-25">
                                <b-form-input
                                  v-model="orderdata.deliverAddress"
                                  id="invoice-data-id"
                                  :placeholder="$t('Address')"
                                  disabled
                                />
                              </b-card-text>
                              <b-card-text class="mb-25">
                                <b-row>
                                  <b-col cols="3">
                                    <b-form-input
                                      v-model="orderdata.deliverZip"
                                      id="invoice-data-id"
                                      :placeholder="$t('Zip')"
                                      disabled
                                    />
                                  </b-col>
                                  <b-col cols="6" class="ml-0">
                                    <b-form-input
                                      v-model="orderdata.deliverCity"
                                      id="invoice-data-id"
                                      :placeholder="$t('City')"
                                      disabled
                                    />
                                  </b-col>
                                  <b-col cols="3">
                                    <b-form-input
                                      v-model="orderdata.deliverCountry"
                                      id="invoice-data-id"
                                      :placeholder="$t('Country')"
                                      disabled
                                    />
                                  </b-col>
                                </b-row>
                              </b-card-text>
                              <b-card-text class="mb-25">
                                <b-row>
                                  <b-col cols="4">
                                    <b-form-input
                                      v-model="orderdata.deliverPhone"
                                      id="invoice-data-id"
                                      :placeholder="$t('Phone')"
                                      disabled
                                    />
                                  </b-col>
                                  <b-col cols="8">
                                    <b-form-input
                                      v-model="orderdata.deliverMail"
                                      id="invoice-data-id"
                                      :placeholder="$t('Email')"
                                      disabled
                                    />
                                  </b-col>
                                </b-row>
                              </b-card-text>
                            </div>
                          </b-col>
                          <b-col cols="1" class="transferphbtn">
                            <button
                              class="tranferDetail"
                              @click="sendPhoneAndMail"
                            >
                              >
                            </button>
                          </b-col>

                          <b-col cols="5" class="div-3">
                            <div class="">
                              <div class="mt-2">
                                <!-- <b-card-text class="mb-25">
                                                            <span class="title">
                                                                {{ $t('Requested Date') }}:
                                                            </span>
                                                            <b-form-input type="date" class="" v-model="orderdata.deliveryDate" id="invoice-data-id" :placeholder="$t('Requested Date')" disabled />
                                                        </b-card-text> -->
                                <!-- <b-card-text class="mb-25">
                                  <span class="title">
                                    {{ $t("Delivery Date") }}:
                                  </span>
                                  <b-form-input
                                    type="date"
                                    class=""
                                    v-model="deliveryDate"
                                    id="invoice-data-id"
                                    :placeholder="$t('YYYY-MM-DD')"
                                  />
                                </b-card-text> -->
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>

                    <b-col cols="6" class="">
                      <div>
                        <b-row>
                          <b-col cols="6">
                            <b-card-text class="mb-25 mt-2">
                              <b-form-input
                                v-model="ordernumber"
                                id="invoice-data-id"
                                :placeholder="$t('Order')"
                                disabled
                              />
                            </b-card-text>
                            <b-card-text class="mb-25">
                              <b-form-input
                                v-model="shop"
                                id="invoice-data-id"
                                :placeholder="$t('Chain')"
                                disabled
                              />
                            </b-card-text>
                          </b-col>

                          <b-col cols="6">
                            <div class="mt-md-0 mt-2">
                              <!-- <div class="d-flex align-items-center mb-1">
                                                        <span class="title span_lbl_mr">
                                                            {{ $t('Provider') }}:
                                                        </span>
                                                        <b-form-input
                                                            id="invoice-data-id"
                                                            v-model="provider"
                                                            class="invoice-filter-select mr-50"
                                                            disabled
                                                        />
                                                        &nbsp;
                                                    </div> -->
                              <div class=" align-items-center">
                                <span class="title span_lbl_mr">
                                  {{ $t("Transport") }}:
                                </span>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Transport"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="filterTransport"
                                    dir="ltr"
                                    :options="transportOptions"
                                    class="invoice-filter-select mr-50 right-label"
                                    :placeholder="$t('Transport')"
                                    :state="errors.length > 0 ? false : null"
                                    @input="getServices($event)"
                                  >
                                    <template #selected-option="{ label }">
                                      <span
                                        class="text-truncate overflow-hidden"
                                      >
                                        {{ label }}
                                      </span>
                                    </template>
                                  </v-select>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                &nbsp;
                                <span class="title span_lbl_mr">
                                  {{ $t("Service") }}:
                                </span>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Service"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="filterService"
                                    dir="ltr"
                                    :options="serviceOptions"
                                    class=" invoice-filter-select mr-50 right-label"
                                    :placeholder="$t('Service')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <template #selected-option="{ label }">
                                      <span
                                        class="text-truncate overflow-hidden"
                                      >
                                        {{ label }}
                                      </span>
                                    </template>
                                  </v-select>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>

                                <span class="title span_lbl_mr ">
                                  {{ $t("Delivery Date") }}:
                                </span>
                                <b-form-input
                                  type="date"
                                  class="invoice-filter-select"
                                  v-model="deliveryDate"
                                  id="invoice-data-id"
                                  :placeholder="$t('YYYY-MM-DD')"
                                />

                                &nbsp;
                              </div>
                              <div class="align-items-center">
                                <!-- <span class="title span_lbl_mr">
                                  {{ $t("Service") }}:
                                </span>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Service"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="filterService"
                                    dir="ltr"
                                    :options="serviceOptions"
                                    class=" invoice-filter-select mr-50"
                                    :placeholder="$t('Service')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <template #selected-option="{ label }">
                                      <span
                                        class="text-truncate overflow-hidden"
                                      >
                                        {{ label }}
                                      </span>
                                    </template>
                                  </v-select>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                                <b-card-text class="mb-25">
                                  <span class="title">
                                    {{ $t("Delivery Date") }}:
                                  </span>
                                  <b-form-input
                                    type="date"
                                    class=""
                                    v-model="deliveryDate"
                                    id="invoice-data-id"
                                    :placeholder="$t('YYYY-MM-DD')"
                                  />
                                </b-card-text> -->
                                &nbsp;
                              </div>
                            </div></b-col
                          >
                        </b-row>
                      </div>
                      <!-- Header: Right Content -->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6" style="max-width: 47%">
                      <div>
                        <div>
                          <b-card-text class="mb-25">
                            <b-form-textarea
                              v-model="orderdata.remark"
                              class="mb-2 mb-lg-0 remark-height"
                              rows="8"
                              disabled
                            />
                          </b-card-text>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="1" style="max-width: 3%; display: flex;">
                      <div class="arrow-btn">
                        <button class="tranferDetail" @click="addMessageDetail">
                          >
                        </button>
                      </div>
                    </b-col>

                    <b-col cols="5">
                      <b-form-textarea
                        v-model="messageDetail"
                        class="mb-2 mb-lg-0 text-box "
                        rows="8"
                        :maxlength="250"
                      />
                      <!-- <span v-if="maxchar" class="maxchar">Message should be contain maximum 250 characters.</span> -->
                    </b-col>
                  
                  </b-row>
                  <!-- <b-row class=" p-1">
                    <b-col cols="3">
                      <b-card-text class="mb-25">
                        <b-form-textarea
                          v-model="orderdata.remark"
                          class="mb-2 mb-lg-0 remark-height"
                          rows="8"
                          disabled
                        />
                      </b-card-text>
                    </b-col>
                    <div class="arrow-btn">
                      <button class="tranferDetail" @click="addMessageDetail">
                        >
                      </button>
                    </div>
                    <b-col cols="9" class="description-box">
                      <div class="mb-0  w-100">
                         <span class="title"> {{ $t("Message") }}: </span> -->
                  <!-- <b-form-textarea
                          v-model="message"
                          id="invoice-data-id"
                          maxlength="50"
                          class="height-40-textarea custom_width"
                        /> 
                        <b-form-textarea
                          v-model="messageDetail"
                          class="mb-2 mb-lg-0 text-box "
                          rows="8"
                        />
                      </div>
                    </b-col>
                  </b-row> -->
                </div>
                <b-row class="mt-0 flex-grow-1 p-0">
                  <b-col cols="4" class="d-flex justify-content-start"> </b-col>
                  <b-col cols="8" class="d-flex justify-content-start"> </b-col>
                </b-row>
                <!-- </div> -->
              </b-card-body>
              <!-- Spacer -->
              <hr class="invoice-spacing" />
              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pt-0 ">
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                    class="d-flex justify-content-start p-0 mb-1 mt-1"
                  >
                    <h4 class="mr-1 mt-0">{{ $t("Labels") }}:</h4>
                    <b-form-input
                      v-model="totalLabels"
                      id="invoice-data-id"
                      type="number"
                      :placeholder="$t('Totallabels')"
                      class="custom-labelnumber mr-1"
                      @change="changeLabelsize"
                    />
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      class="addbtn"
                      @click="addNewItemInItemForm"
                    >
                      {{ $t("Add Label") }}
                    </b-button>

                    <h6
                      v-if="totalOrderlinedata.length > 5 && totalLabels == 5"
                      class="ml-1"
                    >
                      {{ $t("showing only ") }} {{ totalLabels }} {{ "labels" }}
                    </h6>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="d-flex justify-content-center p-0 mb-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="w-50"
                      @click="sendOrderLine()"
                    >
                      {{ $t("Print") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="12" class="p-0">
                    <div v-if="orderlinedata.length > 5" class="demo">
                      <VueSlickCarousel
                        v-bind="slickOptions"
                        v-if="orderlinedata.length > 0"
                      >
                        <div
                          v-for="(item, index) in orderlinedata"
                          :key="index"
                        >
                          <b-row>
                            <b-col class="mr-1 ml-1">
                              <b-row>
                                <b-col
                                  cols="6"
                                  class="d-flex justify-content-start"
                                >
                                  <h6 class="mt-2">
                                    {{ $t("Label") }}: {{ index + 1 }}
                                  </h6>
                                </b-col>
                                <b-col
                                  cols="6"
                                  class="d-flex justify-content-end"
                                >
                                  <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    variant="primary"
                                    class="deletebtn"
                                    @click="deleteOrderline(index)"
                                  >
                                    {{ $t("delete label") }}
                                  </b-button>
                                </b-col> </b-row
                              ><br />
                              <b-row>
                                <b-col
                                  cols="4"
                                  class="d-flex justify-content-start"
                                >
                                  <p class="mb-25">
                                    <b-form-input
                                      v-model="item.qty"
                                      id="invoice-data-id"
                                      :placeholder="$t('Quantity')"
                                    />
                                  </p>
                                </b-col>

                                <b-col
                                  cols="4"
                                  class="d-flex justify-content-start"
                                >
                                  <p class="card-text mb-25 ">
                                    <b-form-input
                                      v-model="item.weight"
                                      id="invoice-data-id"
                                      :placeholder="$t('Weight')"
                                    />
                                  </p>
                                </b-col>
                                <b-col
                                  cols="4"
                                  class="d-flex justify-content-start"
                                >
                                  <p class="card-text mb-25">
                                    <b-form-input
                                      v-model="item.volume"
                                      id="invoice-data-id"
                                      :placeholder="$t('Volume')"
                                    />
                                  </p>
                                </b-col>
                              </b-row>

                              <p class="card-text mb-25">
                                <b-form-textarea
                                  v-model="item.description1"
                                  id="invoice-data-id"
                                  :placeholder="$t('Description')"
                                />
                              </p>
                              <b-col
                                cols="12"
                                class="d-flex justify-content-center"
                                v-if="activeView"
                              >
                                <b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  size="sm"
                                  variant="primary"
                                  class="deletebtn"
                                  @click="viewPDF(index + 1)"
                                >
                                  {{ $t("View PDF") }}
                                </b-button>
                              </b-col>
                            </b-col>
                          </b-row>
                        </div>
                      </VueSlickCarousel>
                    </div>
                    <div v-if="orderlinedata.length <= 5" class="demo1">
                      <b-row>
                        <b-col
                          v-for="(item, index) in orderlinedata"
                          :key="index"
                        >
                          <b-row>
                            <b-col
                              cols="6"
                              class="d-flex justify-content-start"
                            >
                              <h6 class="mt-2">
                                {{ $t("Label") }}: {{ index + 1 }}
                              </h6>
                            </b-col>
                            <b-col cols="6" class="d-flex justify-content-end">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                variant="primary"
                                class="deletebtn"
                                @click="deleteOrderline(index)"
                              >
                                {{ $t("delete label") }}
                              </b-button>
                            </b-col> </b-row
                          ><br />
                          <b-row>
                            <b-col cols="4">
                              <h6>
                                {{ $t("Quantity") }}
                              </h6>
                            </b-col>
                            <b-col cols="4">
                              <h6>
                                {{ $t("Weight") }}
                              </h6>
                            </b-col>
                            <b-col cols="4">
                              <h6>
                                {{ $t("volume") }}
                              </h6>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              cols="4"
                              class="d-flex justify-content-start"
                            >
                              <p class="mb-25">
                                <b-form-input
                                  v-model="item.qty"
                                  id="invoice-data-id"
                                  :placeholder="$t('Quantity')"
                                />
                              </p>
                            </b-col>
                            <b-col
                              cols="4"
                              class="d-flex justify-content-start"
                            >
                              <p class="card-text mb-25">
                                <b-form-input
                                  v-model="item.weight"
                                  id="invoice-data-id"
                                  :placeholder="$t('Weight')"
                                />
                              </p>
                            </b-col>
                            <b-col
                              cols="4"
                              class="d-flex justify-content-start"
                            >
                              <p class="card-text mb-25">
                                <b-form-input
                                  v-model="item.volume"
                                  id="invoice-data-id"
                                  :placeholder="$t('Volume')"
                                />
                              </p>
                            </b-col>
                          </b-row>
                          <p class="card-text mb-25">
                            <b-form-textarea
                              v-if="item.internalText == ''"
                              v-model="item.description1"
                              id="invoice-data-id"
                              :placeholder="$t('Description')"
                            />
                            <b-form-textarea
                              v-else
                              v-model="item.internalText"
                              id="invoice-data-id"
                              :placeholder="$t('Description')"
                            />
                            <!-- <b-form-textarea
                              v-if="item.useInternalText == false"
                              v-model="item.internalText"
                              id="invoice-data-id"
                              :placeholder="$t('Description')"
                            />
                            <b-form-textarea
                              v-if="item.useInternalText == true"
                              v-model="item.internalText"
                              id="invoice-data-id"
                              :placeholder="$t('internalText')"
                            /> -->
                          </p>
                          <b-col
                            cols="12"
                            class="d-flex justify-content-center"
                            v-if="activeView"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              size="sm"
                              variant="primary"
                              class="deletebtn"
                              @click="viewPDF(index + 1)"
                            >
                              {{ $t("View PDF") }}
                            </b-button>
                          </b-col>
                        </b-col>
                      </b-row>
                    </div>

                    <!-- <VueSlickCarousel v-bind="slickOptions" v-if="orderlinedata.length > 0">
                                            <div 
                                                v-for="i in 5" 
                                                :key="i" 
                                                class="img-warpper"
                                            >
                                                <img :src="`https://picsum.photos/200/100?random=${i}`" />
                                            </div>
                                        </VueSlickCarousel> -->
                  </b-col>
                </b-row>
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
              </b-card-body>

              <b-card-body class="invoice-padding">
                <b-row class="mt-1 test">
                  <div
                    class="d-flex border rounded trasport_head_txt"
                    v-for="(item, index) in totalOrderlinedata"
                    :key="index"
                  >
                    <span class="trasport_head_span"
                      >{{ $t("Line") }} - {{ index + 1 }}</span
                    >
                    <b-row class="flex-grow-1 p-1 custom_spiments_div">
                      <b-col class="d-flex left_div">
                        <div class="mr-1 left_div1">
                          <label class="d-inline">{{ $t("GTIN") }}</label>
                          <b-form-input
                            v-model="item.GTIN"
                            type="text"
                            class="gtin-width"
                            disabled
                          />
                        </div>
                        <div class="mr-1 left_div2">
                          <label class="d-inline">{{ $t("SKU") }}</label>
                          <b-form-input
                            v-model="item.SKU"
                            type="text"
                            class=" sku-width"
                            disabled
                          />
                        </div>
                        <div class="left_div3">
                          <label class="d-inline">{{ $t("QTY") }}</label>
                          <b-form-input
                            v-model="item.qty"
                            type="text"
                            class=" qty-width "
                            disabled
                          />
                        </div>
                        <b-col class="left_div4">
                          <label class="d-inline"
                            >{{ $t("Description") }} 1</label
                          >

                          <b-form-textarea
                            v-model="item.description"
                            class="mb-2 mb-lg-0"
                            disabled
                          >
                          </b-form-textarea>
                        </b-col>
                        <b-col class="left_div5">
                          <label class="d-inline">{{
                            $t("InternalText")
                          }}</label>

                          <b-form-textarea
                            v-model="item.internalText"
                            class="mb-2 mb-lg-0"
                            disabled
                          >
                          </b-form-textarea>
                        </b-col>
                        <!-- <div class="mr-1">
                          <label class="d-inline">{{ $t("Description") }}</label>
                          <b-form-input
                            v-model="item.description1"
                            type="text"
                            class="mb-2"
                            disabled
                          />
                        </div> -->
                        <!-- <div class="mr-1">
                          <label class="d-inline">{{ $t("Internal Text") }}</label>
                          <b-form-input
                            v-model="item.internalText"
                            type="text"
                            class="mb-2 qty-width w-50"
                            disabled
                          />
                        </div> -->
                      </b-col>
                      <!-- <b-col cols="12" lg="2"> -->
                      <!-- <label class="d-inline">{{ $t("InternalText") }}</label>
                                                <b-form-input
                                                    v-model="item.internalText"
                                                    type="text"
                                                    class="mb-2"
                                                    disabled
                                                /> -->
                      <!-- </b-col> -->
                    </b-row>
                  </div>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import axios from "axios";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BPagination,
  BLink,
  BImg,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  props: {
    arrayData: Array,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    BImg,
    BPagination,
    flatPickr,
    vSelect,
    Logo,
    VueSlickCarousel,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 5,
      },
      required,
      currentPage: 1,
      filterTransport: "",
      filterService: "",
      totalLabels: 0,
      totalrecords: 0,
      deliveryDate: "",
      totalPrintRecords: this.arrayData.length,
      // ordernumber : this.$route.params.order,
      // shop : this.$route.params.shop,
      // provider : this.$route.params.provider,
      ordernumber: "",
      shop: "",
      provider: "",

      carrierdata: [],
      orderdata: [],
      transportOptions: [],
      serviceOptions: [],
      totalOrderlinedata: [],
      orderlinedata: [],
      itemFormBlankItem: {
        line: "",
        GTIN: "",
        SKU: "",
        qty: "",
        description1: "",
        description2: "",
        internalText: "",
      },
      activeView: false,
      pdfLabels: [],
      message: "",
      messageDetail: "",
      maxchar: false,
    };
  },
  created() {
    localStorage.setItem("title", "labels");
    // console.log("here");

    this.mytoken = localStorage.getItem("token");
    if (this.mytoken == "") {
      localStorage.removeItem("token");
      this.$router.push({ name: "login" });
    } else {
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:8270/login/v1/checktoken/" +
          this.mytoken,
      };
      axios(config)
        .then((response) => {
          if (response.data.token.status == true) {
            this.getOrderdata();
            this.getCarrierdata();
            this.getorderlinedata();

            this.filterTransport = localStorage.getItem("labelTransport");
            this.filterService = localStorage.getItem("labelService");
            console.log(response, "RES");
          }
        })
        .catch(function(error) {
          console.log("error", error);
          if (error.response.status == 404) {
            localStorage.setItem("token", "");
            localStorage.removeItem("username");
            localStorage.removeItem("days");
            localStorage.removeItem("pagesize");
            window.location.href = "/login";
          }
        });
    }
  },
  watch: {
    currentPage: function(val) {
      this.currentPage = val;
      this.getOrderdata();
      this.getCarrierdata();
      this.getorderlinedata();
    },
    totalPrintRecords: function(val) {
      console.log("valgui", val);
      this.totalPrintRecords = val;
      this.getOrderdata();
      this.getCarrierdata();
      this.getorderlinedata();
    },
    messageDetail: function(val){
      this.getmessage(val);
    }
  },
  methods: {

    getmessage(val){
       if(this.messageDetail.length>249){
        alert("Message should be contain maximum 250 characters.");
       }
    },
    getorderlinedata() {
      this.mytoken = localStorage.getItem("token");

      console.log(this.arrayData[this.currentPage - 1].deliveryDate, "uujn");
      const x = this.arrayData[this.currentPage - 1].deliveryDate;
      if (x == "" || x == null) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate() + 1).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        this.deliveryDate = formattedDate;
      } else {
        this.deliveryDate = this.arrayData[this.currentPage - 1].deliveryDate;
      }
      (this.ordernumber = this.arrayData[this.currentPage - 1].orderNumber),
        (this.shop = this.arrayData[this.currentPage - 1].shop),
        axios(
          "https://engine.netsupport.dk:8270/transport/v1/orderlines/" +
            this.mytoken +
            "?order=" +
            this.ordernumber +
            "&shop=" +
            this.shop
        )
          .then((responseorderline) => {
            console.log("_______234", responseorderline);
            this.messageDetail = "";
            this.totalOrderlinedata = JSON.parse(
              JSON.stringify(responseorderline.data.articles)
            );
            this.totalrecords = this.totalOrderlinedata.length;
            this.orderlinedata = this.totalOrderlinedata.slice(0, 5);
            console.log(
              "this.totalOrderlinedata ===> ",
              this.totalOrderlinedata
            );
            this.totalLabels = this.orderlinedata.length;

            this.itemFormBlankItem.description1 = this.orderlinedata[
              this.totalLabels - 1
            ].description1;
          })
          .catch(function(error) {
            console.log(error);
            // if(error.response.status == 403)
            // {
            //     localStorage.setItem("token", "");
            //     window.location.href = '/login';
            // }
          });
    },
    getOrderdata() {
      this.mytoken = localStorage.getItem("token");
      (this.ordernumber = this.arrayData[this.currentPage - 1].orderNumber),
        (this.shop = this.arrayData[this.currentPage - 1].shop),
        axios(
          "https://engine.netsupport.dk:8270/transport/v1/order/" +
            this.mytoken +
            "?order=" +
            this.ordernumber +
            "&shop=" +
            this.shop
        )
          .then((responseorder) => {
            console.log("responseorder", responseorder);
            this.orderdata = JSON.parse(
              JSON.stringify(responseorder.data.deliverOrder[0])
            );
          })
          .catch(function(error) {
            console.log(error);
            // localStorage.setItem("token", "");
            // window.location.href = '/login';
          });
    },
    getCarrierdata() {
      this.carrierdata = [];
      this.serviceOptions = [];
      this.transportOptions = [];
      this.mytoken = localStorage.getItem("token");
      (this.provider = this.arrayData[this.currentPage - 1].provider),
        axios(
          "https://engine.netsupport.dk:8270/transport/v1/carriers/" +
            this.mytoken +
            "?provider=" +
            this.provider
        )
          .then((responsecarrier) => {
            this.carrierdata = responsecarrier.data.carriers;
            //var service = [];
            var transport = [];
            this.carrierdata.forEach((element) => {
              transport.push(element.Carrier.trim());
              //service.push(element.Service.trim());
            });
            this.transportOptions = [...new Set(transport)];

            // console.log(this.transportOptions);
            //this.serviceOptions = [...new Set(service)];
          })
          .catch(function(error) {
            console.log(error);
          });
    },
    getServices(event) {
      //console.log(event);
      var service = [];
      var serviceArray = this.carrierdata.filter(function(el) {
        return el.Carrier.trim() == event.trim();
      });

      serviceArray.forEach((element) => {
        service.push(element.Service.trim());
      });
      this.serviceOptions = [...new Set(service)];
    },
    changeLabelsize() {
      this.orderlinedata = this.totalOrderlinedata.slice(0, this.totalLabels);
    },

    addNewItemInItemForm() {
      this.orderlinedata.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.totalLabels = this.orderlinedata.length;
    },

    deleteOrderline(index) {
      if (confirm("Are you sure to delete this label?")) {
        this.orderlinedata.splice(index, 1);
        this.totalLabels = this.orderlinedata.length;
      } else {
        alert("Your Data is Safe");
      }
    },

    addMessageDetail() {
      if (!this.messageDetail) {
        this.messageDetail = this.messageDetail + this.orderdata.remark;
        return;
      }
      this.messageDetail = this.messageDetail + "\n" + this.orderdata.remark;
    },

    sendPhoneAndMail() {
      if (!this.messageDetail) {
        this.messageDetail =
          this.messageDetail +
          "Mail: " +
          this.orderdata.deliverMail +
          "\n" +
          "Phone Number: " +
          this.orderdata.deliverPhone;
        return;
      }
      this.messageDetail =
        this.messageDetail +
        "\n" +
        "Mail: " +
        this.orderdata.deliverMail +
        "\n" +
        "Phone Number: " +
        this.orderdata.deliverPhone;
    },

    sendOrderLine() {
      console.log("______________");
      // console.log("orderrrrrr", this.orderlinedata);
      // console.log("this.totalOrderlinedata",this.totalOrderlinedata);

      //   console.log("checking",this.item);
      this.$refs.transportValidation.validate().then((success) => {
        // if (success) {
        // console.log("success");
        localStorage.setItem("labelTransport", this.filterTransport);
        localStorage.setItem("labelService", this.filterService);
        // console.log("this.orderlinedata=",this.orderlinedata);
        var packagedata = [];
        this.orderlinedata.forEach((element, index) => {
          console.log("element", element);
          packagedata.push({
            labelnumber: index + 1,
            packageWeight: element.weight,
            packageVolume: element.volume,
            packageDescription:
              element.internalText == ""
                ? element.description
                : element.internalText,
            packageQTY: element.qty,
            gtin: element.GTIN,
            sku: element.SKU,
          });
        });

        console.log(".orderlinedata", this.orderlinedata);

        var senddata = {
          orderinfo: {
            shop: this.shop,
            order: this.ordernumber,
            provider: this.provider,
            carrier: this.filterTransport,
            service: this.filterService,
            remark: this.messageDetail,
            deliveryDate: this.deliveryDate,
          },
          packages: packagedata,
        };

        console.log("senddata ", senddata);

        let token = localStorage.getItem("token");

        var config = {
          method: "post",
          url:
            "https://engine.netsupport.dk:8270/transport/v1/createlabels/" +
            this.mytoken,
          headers: {
            "Content-Type": "application/json",
          },
          data: senddata,
        };
        axios(config)
          .then((response) => {
            console.log("response==========", response);
            console.log("response success");
            this.arrayData.splice(this.currentPage - 1, 1);
            this.totalPrintRecords = this.arrayData.length;

            if (response.data.labelStatus == "ok") {
              console.log("1");
              if (
                response.data.pdfLabels == "" ||
                response.data.pdfLabels == null
              ) {
                console.log("2");
                alert(response.data.message);
              } else {
                console.log("3");
                alert(response.data.message);
                this.activeView = true;

                response?.data?.pdfLabels?.forEach((element, index) => {
                  element.labelline = index + 1;
                });
                console.log("4");

                this.pdfLabels = response.data.pdfLabels;
              }
            }
          })
          .catch(function(error) {
            // alert("Freight label not delivered");
            console.log("error", error);
            alert(error);
          });
      });
    },
    viewPDF(index) {
      //console.log("index",index);
      window.open(
        this.pdfLabels.find((e) => e.labelline == index)?.packageURL,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
::v-deep .slick-prev::before,
::v-deep .slick-next::before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #767676 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom_width {
  min-width: 44em;
}

.maxchar{
  color:red;
  font-size: 16px;
}
.demo1 .col {
  width: 20%;
  max-width: 20% !important;
}

.tranferDetail {
  border: 1px solid rgb(180, 178, 178);
  color: black;
  padding: 2px 8px;
  margin: auto;
}

.adddd {
  padding: 0px;
}

.arrow-btn {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 5px;
  margin: auto;
}
.description-box {
  max-width: 73%;
}
.div-3 {
  margin-left: 12px;
}

.transferphbtn {
  display: flex;
  align-items: center;
  margin-top: 126px;
  margin-left: 3%;
}

.right-label {
  margin-bottom: 4px;
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
  .trasport_head_span {
    background-color: #283046;
  }
}

.form-item-section .row .border {
  background-color: white;
}

.deletebtn {
  margin-top: 6px;
  margin-left: 15px;
}

.invoice-filter-select {
  width: 63%;
}

.trasport_head_txt {
  position: relative;
  margin-bottom: 2em;
  width: 100%;
}

.trasport_head_span {
  position: absolute;
  bottom: 115px;
  left: 20px;
  padding: 10px 20px;
  background-color: #fff;
  font-size: 16px;
}

.linebtn {
  padding: 12px;
  font-size: 15px;
}

.custom-labelnumber {
  width: 12%;
  margin-top: -10px;
}
.addbtn {
  margin-top: -10px;
  padding: 12px;
}
.span_lbl_mr {
  width: 85px;
}
.span_lbl_mr_2 {
  width: 65px;
}
.height-40-textarea {
  height: 40px;
}

// .gtin-width{
//     max-width: 70%;
// }
// .sku-width{
//     max-width: 60%;
// }
// .qty-width{
//     max-width: 30%;
// }

.left_div {
  display: flex;
  align-items: flex-start;
}
.left_div1 {
  width: 15%;
}
.left_div2,
.left_div3 {
  width: 10%;
}
.left_div4 {
  padding-right: 0 !important;
}
</style>
