<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="filtershop"
            dir="ltr"
            :options="shopOptions"
            class="invoice-filter-select mr-50"
            :placeholder="$t('Shop')"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </v-select>
          &nbsp;
          <b-form-input
            v-model="filterGtin"
            class="d-inline-block mr-50 advance-search"
            :placeholder="$t('GTIN')"
          />

          <b-form-input
            v-model="filterSku"
            class="d-inline-block mr-50 advance-search"
            :placeholder="$t('SKU')"
          />

          <!-- <b-form-input
            v-model="filterCity"
            class="d-inline-block mr-50 advance-search"
            :placeholder="$t('City')"
          /> -->

          <!-- <b-form-input
            v-model="filterPostal"
            class="d-inline-block mr-50 advance-search"
            :placeholder="$t('Postal')"
          /> -->
        </b-col>
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filter"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Advance search')"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <v-select
                        v-model="filterselectedOrder"
                        dir="ltr"
                        :options="orderOptions"
                        @input ="multipleAction($event)"
                        class="invoice-filtercheck-select mt-1"
                        :placeholder="$t('Actions')"
                        label="text"
                    >
                        <template #selected-option="{ text }">
                            <span> {{ $t(text) }} </span>
                        </template>

                        <template #option="{ text }">
                            <span> {{ $t(text) }} </span>
                        </template>
                    </v-select>&nbsp; -->

          <label class="mt-1">{{ $t("Days") }}</label>
          <v-select
            v-model="filterdays"
            dir="ltr"
            :options="daysOptions"
            :clearable="false"
            class="invoice-filterday-select mt-1 ml-50"
            :placeholder="$t('Days')"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template> </v-select
          >&nbsp;
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 float-right mr-1"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <label class="mt-1">{{ $t("Pagesize") }}</label>
          <v-select
            v-model="perPage"
            dir="ltr"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-50 mt-1"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
          class="
            d-flex
            align-items-center
            justify-content-start
            mb-1 mb-md-0
            mt-1
          "
        >
          <!-- <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="PR"
            @click="multipleAction"
          >
            {{ $t("Export") }}
          </b-button> -->
          <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="PR"
            @click="DownloadData()"
            :max-size="2000"
            :min-size="2000"
          >
            {{ $t("Export") }}
          </b-button>
          <div class="upload-btn-wrapper">
            <b-button 
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary">{{ $t("Import") }}
            </b-button>
            <input 
            class="input-file__input"
            ref="file"
            @change="ImportDataFile($event)"
            type="file"
            id="files"
            model="FileName"
            name="myfile" 
            accept=".xlsx"
            />
            </div>
          <!-- <input
            class="input-file__input"
            ref="file"
            @change="ImportDataFile($event)"
            type="file"
            id="files"
            model="FileName"
          /> -->
          <!-- <label
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="SW"
          >
            {{ $t("Import") }}
          </label> -->

          <!-- <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="PU"
             @click="multipleAction"
          >
            {{ $t("Delete Database") }}
          </b-button> -->

          <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="PU"
            @click="DeleteDataDatbase"
          >
            {{ $t("Delete Database") }}
          </b-button>

          <!-- <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="SI"
            @click="multipleAction"
          >
            {{ $t("send to invoice") }}
          </b-button>

          <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="DL"
            @click="multipleAction"
          >
            {{ $t("Delete") }}
          </b-button>
          <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            id="CF"
            @click="multipleAction"
          >
            {{ $t("confirm") }}
          </b-button> -->
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="this.orderdata"
      responsive
      :fields="tableColumns"
      :filter="filter"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <div></div>
      <template #head(checked_id)>
        <input
          type="checkbox"
          @click="checkallOrder($event)"
          name="order_checkedall[]"
          class="check-btn"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(checked_id)="data" class="check-main">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          <input
            type="checkbox"
            @click="checkOrder($event)"
            name="order_checked[]"
            class="check-btn"
            :value="data.item.orderNumber"
            :id="data.item.orderNumber"
            disabled
          />
        </div>
        <div v-else>
          <input
            type="checkbox"
            @click="checkOrder($event)"
            name="order_checked[]"
            class="check-btn"
            :value="data.item.orderNumber"
            :id="data.item.orderNumber"
            :checked="checkall"
          />
        </div>
      </template>

      <template #cell(id)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          <a href="javascript:void(0)" class="font-weight-bold deleted-row">
            {{ data.item.orderNumber }}
          </a>
        </div>
        <div v-else>
          <b-link
            :to="
              '/order-preview/' + data.item.orderNumber + '/' + data.item.shop
            "
            class="font-weight-bold"
          >
            {{ data.item.orderNumber }}
          </b-link>
        </div>
      </template>

      <template #cell(shop)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.shop }}
        </div>
        <div v-else>
          {{ data.item.shop }}
        </div>
      </template>
      <template #cell(Price)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.Price }}
        </div>
        <div v-else>
          {{ data.item.Price }}
        </div>
      </template>

      <!-- Column: Invoice Status -->

      <!-- <template #cell(buyername)="data">
                <div v-if="(data.item.DEL == 'Y')" class="deleted-row">
                    {{ data.item.buyerName }}
                </div>
                <div v-else>
                    {{ data.item.buyerName }}
                </div>
            </template> -->

      <!-- <template #cell(buyercity)="data">
                <div v-if="(data.item.DEL == 'Y')" class="deleted-row">
                    {{ data.item.buyerCity }}
                </div>
                <div v-else>
                    {{ data.item.buyerCity }}
                </div>
            </template> -->

      <template #cell(MaxP)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.MaxP }}
        </div>
        <div v-else>
          {{ data.item.MaxP }}
        </div>
      </template>

      <template #cell(MinP)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.MinP }}
        </div>
        <div v-else>
          {{ data.item.MinP }}
        </div>
      </template>

      <template #cell(EcoPrice)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.EcoPrice }}
        </div>
        <div v-else>
          {{ data.item.EcoPrice }}
        </div>
      </template>
      <template #cell(itemSize)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.itemSize }}
        </div>
        <div v-else>
          {{ data.item.itemSize }}
        </div>
      </template>
      <template #cell(Description)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.Description }}
        </div>
        <div v-else>
          {{ data.item.Description }}
        </div>
      </template>
      <template #cell(sku)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.sku }}
        </div>
        <div v-else>
          {{ data.item.sku }}
        </div>
      </template>
      <template #cell(internGroup)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.internGroup }}
        </div>
        <div v-else>
          {{ data.item.internGroup }}
        </div>
      </template>

      <template #cell(gtin)="data">
        <div v-if="data.item.DEL == 'Y'" class="deleted-row">
          {{ data.item.gtin }}
        </div>
        <div v-else>
          {{ data.item.gtin }}
        </div>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Send Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="
              $router.push(
                '/order-preview/' + data.item.orderNumber + '/' + data.item.shop
              )
            "
          />
          <b-tooltip
            title="preview order"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">{{ $t("Download") }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="
                '/order-edit/' + data.item.orderNumber + '/' + data.item.shop
              "
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted">
            {{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
            {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
            {{ $t("entries") }}
          </span>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import axios from "axios";
import i18n from "@/libs/i18n/index";
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Labels from "./Labels.vue";

export default {
  props: {},
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    Labels,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      perPageOptions: [10, 20, 40, 60, 80, 100],
      totalInvoices: "",
      perPage: "10",
      currentPage: "1",
      filter: "",
      filtershop: "",
      filterdays: 7,
      filterSku: "",
      filterCity: "",
      filterPostal: "",
      filterGtin: "",
      filteroffset: 0,
      filterselectedOrder: "",
      checkall: false,
      arrayBuffer: null,
      filelist: null,
      fileGet: "",
      FileDataGet: "",

      tableColumns: [
        // { key: "checked_id", label: "" },
        // { key: "HdrId", label: i18n.t("HdrId"), sortable: true },
        {
          key: "shop",
          label: i18n.t("Shop"),
          sortable: true,
        },
        {
          key: "Price",
          label: i18n.t("Price"),
          sortable: true,
        },
        // { key: "PRN,DSP,INV,DEL,CNF" },
        // { key: "buyername", label: i18n.t('buyerName'), sortable: true },
        // { key: "buyercity", label: i18n.t('buyerCity'), sortable: true },
        {
          key: "MinP",
          label: i18n.t("MinP"),
          sortable: true,
        },
        {
          key: "MaxP",
          label: i18n.t("MaxP"),
          sortable: true,
        },
        {
          key: "EcoPrice",
          label: i18n.t("EcoPrice"),
          sortable: true,
        },
        {
          key: "internGroup",
          label: i18n.t("internGroup"),
          sortable: true,
        },
        {
          key: "itemSize",
          label: i18n.t("itemSize"),
          sortable: true,
        },
        {
          key: "sku",
          label: i18n.t("sku"),
          sortable: true,
        },
        {
          key: "gtin",
          label: i18n.t("gtin"),
          sortable: true,
        },
        {
          key: "Description",
          label: i18n.t("Description"),
          sortable: true,
        },
        // { key: "actions", label: i18n.t("actions") },
      ],

      daysOptions: ["1", "7", "14", "30", "365"],
      shopOptions: [],
      orderOptions: [
        {
          text: "print",
          value: "print",
        },
        {
          text: "send-to-warehouse",
          value: "SW",
        },
        {
          text: "picked-up",
          value: "PU",
        },
        {
          text: "send to invoice",
          value: "SI",
        },
        {
          text: "delete",
          value: "DL",
        },
        {
          text: "confirm",
          value: "CF",
        },
      ],
      mytoken: "",

      orderdata: [],
      actionData: [],

      dataMeta: {
        from: 1,
        to: this.perPage,
        of: this.totalInvoices,
      },
    };
  },

  updated() {
    const f = this.perPage * (this.currentPage - 1);
    this.dataMeta.from = f + 1;
    this.dataMeta.to = this.perPage * this.currentPage;
  },

  watch: {
    perPage: function () {
      this.getorderdata();
    },
    filterdays: function (val, oldval) {
      this.filterdays = val;
      this.getorderdata();
    },
    filtershop: function (val) {
      this.filtershop = val;
      this.getorderdata();
    },
    filterSku: function (val) {
      this.filterSku = val;
      this.getorderdata();
    },
    filterCity: function (val) {
      this.filterCity = val;
      this.getorderdata();
    },
    filterPostal: function (val) {
      this.filterPostal = val;
      this.getorderdata();
    },
    filterGtin: function (val) {
      this.filterGtin = val;
      this.getorderdata();
    },
    filteroffset: function (val) {
      this.filteroffset = val;
      this.getorderdata();
    },
    currentPage: function (val) {
      this.currentPage = val;
      this.getorderdata();
    },
  },

  created() {
    // console.log("shopOptions", this.shopOptions);
    this.mytoken = localStorage.getItem("token");
    if (this.mytoken == "") {
      localStorage.removeItem("token");
      this.loggedIn = false;
      this.$router.push({
        name: "login",
      });
    } else {
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:8270/login/v1/checktoken/" +
          this.mytoken,
      };
      axios(config)
        .then((response) => {
          if (response.data.token.status == true) {
            this.filterdays = localStorage.getItem("days");
            if (this.filterdays == null) {
              this.filterdays = 7;
            }

            this.perPage = localStorage.getItem("pagesize");
            if (this.perPage == null) {
              this.perPage = "10";
            }

            this.getorderdata();
            this.getshops();
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status == 404) {
            localStorage.setItem("token", "");
            localStorage.removeItem("username");
            localStorage.removeItem("days");
            localStorage.removeItem("pagesize");
            window.location.href = "/login";
          }
        });
    }
  },
  methods: {
    async ImportDataFile() {
      this.fileGet = this.$refs.file.files[0];
      console.log("this.fileGet", this.fileGet.size);
      const SizeFile = this.fileGet.size;
      // const files =  document.getElementById("files")
      const formData = new FormData();
      formData.append("xlsx", this.fileGet); 
      console.log("formData", formData);
      if (SizeFile > 256000) {
        alert("select the 100KB less than ");
      } else if (SizeFile < 20000) { 
        alert("select the 20KB greater than");
      } else {
        try {
          await axios
            .post(
              "https://engine.netsupport.dk:8270/pricing/v1/upload/" +
                this.mytoken,
              formData,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log("response", response);
            });
        } catch (error) {
          console.log("error", error);
        }
      }
    },

    DeleteDataDatbase() {
      this.mytoken = localStorage.getItem("token");

      if (confirm("Are you sure you want to delete this all data ?")) {
        var config = {
          method: "delete",
          url:
            "https://engine.netsupport.dk:8270/pricing/v1/deletecontents/" +
            this.mytoken,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then((response) => {
            alert(response.data.status);
          })
          .catch(function (error) {
            console.log(error);
            alert("Price detail not deleted.");
          });
      } else {
        alert("Your Data is Safe");
      }
    },
    DownloadData() {
      this.mytoken = localStorage.getItem("token");
      axios(
        "https://engine.netsupport.dk:8270/pricing/v1/download/" + this.mytoken,
        {
          responseType: "blob",
        }
      ).then((response) => {
        console.log(response);
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `data.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    getorderdata() {
      this.mytoken = localStorage.getItem("token");
      localStorage.setItem("days", this.filterdays);
      localStorage.setItem("pagesize", this.perPage);
      const offset = this.perPage * (this.currentPage - 1);
      this.filteroffset = offset;
      axios(
        "https://engine.netsupport.dk:8270/pricing/v1/headers/" +
          this.mytoken +
          "?offset=" +
          this.filteroffset +
          "&pagesize=" +
          this.perPage +
          "&sku=" +
          this.filterSku +
          "&gtin=" +
          this.filterGtin +
          "&shop=" +
          this.filtershop
      )
        .then((responseorder) => {
          console.log(responseorder.data.inventory.recordset);
          this.dataMeta.of = responseorder.data.inventory.MaxRecords;
          this.totalInvoices = responseorder.data.inventory.MaxRecords;
          this.orderdata = JSON.parse(
            JSON.stringify(responseorder.data.inventory.recordset)
          );

          console.log("this.orderdata", this.orderdata);
        })
        .catch(function (error) {
          if (error.response.status == 403) {
            localStorage.setItem("token", "");
            window.location.href = "/login";
          }
        });
    },

    getshops() {
      this.mytoken = localStorage.getItem("token");
      axios("https://engine.netsupport.dk:8270/orders/v1/shops/" + this.mytoken)
        .then((responseshop) => {
          this.shopOptions = JSON.parse(
            JSON.stringify(responseshop.data.shops)
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    checkallOrder(event) {
      let temp = [];
      if (event.target.checked) {
        this.checkall = true;
        this.orderdata.forEach((element) => {
          temp.push(element.orderNumber);
        });

        this.actionData = temp;
      } else {
        this.checkall = false;
        this.actionData = [];
      }
    },

    checkOrder(event) {
      if (event.target.checked) {
        this.actionData.push(event.target.value);
      } else {
        this.actionData = this.actionData.filter((f) =>
          f !== event.target.value ? this.actionData : ""
        );
      }
    },

    // multipleAction(event) {
    //     // console.log(this.actionData);
    //     if (this.actionData.length == 0) {
    //         alert("Please Select One Order");
    //     } else {
    //         let temp = [];
    //         //console.log(this.orderdata);
    //         this.orderdata.forEach((element) => {
    //             this.actionData.forEach((ele) => {
    //                 if (element.orderNumber == ele) {
    //                     temp.push({
    //                         orderNumber: ele,
    //                         hdrid: element.hdrid,
    //                         action: event.target.id,
    //                     });
    //                 }
    //             });
    //         });

    //         var submitData = JSON.stringify({
    //             lines: temp,
    //         });

    //         // console.log(submitData);
    //         // console.log("temp", this.orderdata);
    //         this.mytoken = localStorage.getItem("token");
    //         var config = {
    //             method: "put",
    //             url: "https://engine.netsupport.dk:8270/orders/v1/actions/" +
    //                 this.mytoken,
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             data: submitData,
    //         };
    //         axios(config)
    //             .then((response) => {
    //                 // console.log(response.data);
    //                 alert("Action Succuessfully Applied.");
    //                 //alert('Action '+response.data.actions);
    //                 this.actionData.forEach((element) => {
    //                     document.getElementById(element).checked = false;
    //                 });
    //                 this.getorderdata();
    //                 temp = [];
    //                 this.actionData = [];
    //             })
    //             .catch(function (error) {
    //                 alert("Data not Updated.");
    //             });
    //     }
    // },
  },
};
</script>

<style>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btnbtn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.Light_blue {
  background-color: lightblue;
}

.orange_color {
  background-color: orange;
}

.light_green {
  background-color: lightgreen;
}

.dark_green {
  background-color: green;
}
</style><style lang="scss" scoped>
.per-page-selector {
  max-width: 100%;
}

.invoice-filter-select {
  //min-width: 190px;
  min-width: 170px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.invoice-filterday-select {
  min-width: 70px;
}

.success_icn {
  background-color: #4cbb17;
  color: white;
}

.danger_icn {
  background-color: #ff6347;
  color: white;
}

.warning_icn {
  background-color: #fdde6c;
  color: white;
}

.secondary_icn {
  background-color: darkgray;
  color: white;
}

.check-btn {
  transform: scale(02);
  width: 12px;
  height: 12px;
}

.invoice-filtercheck-select {
  width: 60%;
}

.deleted-row {
  color: #ff6347 !important;
}

.vs__actions svg {
  fill: #95989f !important;
}
</style><style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
